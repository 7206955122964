import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import {SidebarProvider} from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import {DbProvider} from "./contexts/DbContext";
import {AuthProvider} from "./contexts/AuthContext";

ReactDOM.render(
    <HelmetProvider>{}
        <SidebarProvider>
            <AuthProvider>
                <DbProvider>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </DbProvider>
            </AuthProvider>
        </SidebarProvider>
    </HelmetProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
