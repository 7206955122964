import React, {useEffect, useRef, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem, ListItemButton,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import { NavLink as RouterLink } from 'react-router-dom';
import {useAuth} from "../../../../contexts/AuthContext";
import {useUsers} from "../../../../contexts/UsersContext";
import {Skill} from "../../../../types/Skill";
import {extractAllSkills, Log} from "../../../../utils/utils";
import {useDb} from "../../../../contexts/DbContext";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)};
        font-family: 'Oswald, serif';
`
);

function HeaderUserbox() {
  //const userData = JSON.parse(localStorage.getItem('userData'));
  // const user = {
  //   firstname: userData.firstname,
  //   lastname: userData.lastname,
  //   avatar: '/static/images/avatars/angelo200x200.jpg',
  //   jobtitle: 'Admin'
  // };
  const {me, image, fetchOwnerProfilePicture, refreshMe, logout} = useAuth();
  const {t} = useDb();
  const navigate = useNavigate();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [skills, setSkills] = useState<Skill[]>([]);

  useEffect(() => {

    const loadOwnerImage = async ()=>{
      await fetchOwnerProfilePicture();
    }

    if (!image)
    {
      loadOwnerImage();
      return;
    }

    const _imageUrl = URL.createObjectURL(image);
    setImageUrl(_imageUrl);

    // Pulizia: Revoca l'oggetto URL quando il componente viene smontato
    return () => {
      if (_imageUrl) {
        URL.revokeObjectURL(_imageUrl);
      }
    };

  }, [image]);

  useEffect(() => {
    Log.debug('Called userbox refresh!')
    if (!me){
      refreshMe();
      return
    }

    setSkills(extractAllSkills(me));

  }, [me]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  function handleSignOut(){
    Log.debug('Sign Out');
    logout();
    navigate(`/`,{replace:true});
  }

  if (!me) return null;

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={`${me.firstname} ${me.lastname}`} src={imageUrl}/>
        {/*<Hidden mdDown>*/}
          <UserBoxText sx={{display:{ xs: 'none', md: 'block' }}}>
            <UserBoxLabel variant="body2" sx={{fontFamily: 'Oswald, serif'}}>{me.firstname} {me.lastname}</UserBoxLabel>
            {/* <UserBoxLabel variant="body1">{firstname}</UserBoxLabel> */}
            <UserBoxDescription variant="body2" sx={{fontFamily: 'Oswald, serif'}}>
              {skills.length>0
                  ?t(skills[0].name)
                  :''}
            </UserBoxDescription>
          </UserBoxText>
        {/*</Hidden>*/}
        {/*<Hidden smDown>*/}
          <ExpandMoreTwoToneIcon sx={{ ml: 1, display:{ xs: 'none', md: 'block' } }} />
        {/*</Hidden>*/}
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={`${me.firstname} ${me.lastname}`} src={imageUrl}/>
          <UserBoxText>
            <UserBoxLabel variant="body1" sx={{fontFamily: 'Oswald, serif', fontSize: 'medium'}}>{me.firstname} {me.lastname}</UserBoxLabel>
            <UserBoxDescription variant="caption" sx={{fontFamily: 'Oswald, serif'}}>
              {skills.length>0
                  ?t(skills[0].name)
                  :''}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        {/* TODO: these are the menu user box options*/}
        {/*<List sx={{ p: 1 }} component="nav">*/}
        {/*  <ListItemButton*/}
        {/*            to={`/management/profile/details/${me.id}`}*/}
        {/*            component={NavLink}>*/}
        {/*    <AccountBoxTwoToneIcon fontSize="small" />*/}
        {/*    <ListItemText primary="Profilo" primaryTypographyProps={{fontFamily: 'Oswald, serif', fontSize: 'medium'}}/>*/}
        {/*  </ListItemButton>*/}
        {/*  <ListItemButton*/}
        {/*      to={`/management/profile/settings/${me.id}`}*/}
        {/*      component={NavLink}*/}
        {/*  >*/}
        {/*    <AccountTreeTwoToneIcon fontSize="small" />*/}
        {/*    <ListItemText primary="Preferenze" primaryTypographyProps={{fontFamily: 'Oswald, serif', fontSize: 'medium'}}/>*/}
        {/*  </ListItemButton>*/}
        {/*</List>*/}
        {/*<Divider />*/}
        <Box sx={{ m: 1 }}>
          <Button color="primary" sx={{fontFamily: 'Oswald, serif', fontSize:'16px'}} fullWidth onClick={handleSignOut}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }}/>
            Log out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
