import React from "react";
import {Avatar, Box, Card, CardContent, Chip, Typography, useTheme} from "@mui/material";
import {ProjectStats} from "../../pages/users/profile/OverviewPage";
import {styled} from "@mui/material/styles";
import {
    FolderOpenTwoTone, ImageTwoTone,
} from "@mui/icons-material";
import {Log} from "../../utils/utils";
import {useDb} from "../../contexts/DbContext";

interface ProjectsStatsCardProps {
    projectStats: ProjectStats;
    sx?:object;
    OnSelected?: (id:string) => void;
}

const RootWrapper = styled(Card)(
    ({ theme }) => `
    background: ${theme.colors.alpha.black[100]};
    color: ${theme.colors.alpha.white[100]};
`
);

const AvatarStatItem = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
`
);

const TypographySecondary = styled(Typography)(
    ({ theme }) => `
      color: ${theme.colors.alpha.white[70]};
`
);

export const ProjectStatsCard: React.FC<ProjectsStatsCardProps>=({projectStats, sx={}, OnSelected})=>{

    const theme = useTheme();
    const {t} = useDb();

    return (

        <Box sx={sx}>

            <RootWrapper
                sx={{
                    p: 2
                }}
                onClick={(e)=>{
                    if (OnSelected){
                        OnSelected('ProjectStatsCard');
                    }
                }}
            >

                <Typography
                    variant="h3"
                    sx={{
                        px: { xs: 1, sm: 2 },
                        pb: 1,
                        pt: 2,
                        fontFamily: 'Oswald, serif',
                        fontSize: { xs: theme.typography.pxToRem(18), sm: theme.typography.pxToRem(23) },
                        color: `${theme.colors.alpha.white[100]}`
                    }}
                >
                    {t('Projects')}
                </Typography>

                <CardContent>
                    <Box
                        display="flex"
                        sx={{
                            px: { xs: 1, sm: 2 },
                            pb: 3
                        }}
                        alignItems="center"
                    >
                        <AvatarStatItem
                            sx={{
                                mr: { xs: 1, sm: 2 }, // Margin a destra solo su schermi più grandi
                                mb: { xs: 1, sm: 0 }, // Margin inferiore sui dispositivi piccoli
                            }}
                            variant="rounded"
                        >
                            <FolderOpenTwoTone fontSize="large" />
                        </AvatarStatItem>
                        <Box>
                            <Typography variant="h1" sx={{fontFamily:'Oswald, serif', fontSize: { xs: '1.5rem', sm: '2rem' }}}>{projectStats
                                ?projectStats.count
                                :'n/a' }</Typography>
                            <TypographySecondary variant="subtitle2" noWrap sx={{fontFamily:'Oswald, serif'}}>
                                {t('Available Projects')}
                            </TypographySecondary>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        sx={{
                            px: { xs: 1, sm: 2 },
                            pb: 3
                        }}
                        alignItems="center"
                    >
                        <AvatarStatItem
                            sx={{
                                mr: { xs: 1, sm: 2 }, // Margin a destra solo su schermi più grandi
                                mb: { xs: 1, sm: 0 }, // Margin inferiore sui dispositivi piccoli
                            }}
                            variant="rounded"
                        >
                            <ImageTwoTone fontSize="large" />
                        </AvatarStatItem>
                        <Box>
                            <Typography variant="h1" sx={{fontFamily:'Oswald, serif',fontSize: { xs: '1.5rem', sm: '2rem' }}}>{projectStats
                                ?projectStats.contents
                                :'n/a' }</Typography>
                            <TypographySecondary variant="subtitle2" noWrap sx={{fontFamily:'Oswald, serif'}}>
                                {t('Total Contents')}
                            </TypographySecondary>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                            px: { xs: 1, sm: 2 },
                            pb: 3
                        }}
                        alignItems="start"
                    >

                        <Typography variant="h2" sx={{fontFamily:'Oswald, serif', mt:0.4, fontSize: { xs: '1rem', sm: '1.25rem' }}}>
                            {t('Skills')}:
                        </Typography>

                        {projectStats?.skills?.length === 0 && (
                            <TypographySecondary variant="subtitle2" noWrap sx={{fontFamily:'Oswald, serif'}}>
                                {t('None')}
                            </TypographySecondary>
                        )}

                        {projectStats
                            ?projectStats.skills?.map((option,index) => (
                                <Chip
                                    key={index}
                                    sx={{
                                        mt: { xs: 1, sm: 2 },
                                        mr: { xs: 0.5, sm: 1 },
                                        fontFamily:'Oswald, serif',
                                        fontSize: { xs: '14px', sm: '16px' },
                                    }}
                                    size="medium"
                                    label={t(option)}
                                    color="warning"
                                    onClick={()=>{Log.debug(option)}}
                                />
                            ))
                            :(<TypographySecondary variant="subtitle2" noWrap sx={{fontFamily:'Oswald, serif'}}>
                                {t('None')}
                            </TypographySecondary>)}

                    </Box>

                </CardContent>

            </RootWrapper>

        </Box>

    );
}
