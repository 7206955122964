import {createContext, useContext, useReducer} from "react";
import {
    getPasswordResetToken,
    isAuthenticated,
    login as userLogin,
    logout as userLogout,
    passwordReset, refreshTokenForced, refreshTokenIfNeeded,
} from "../services/authService";
import {applyDeleteToken, getCoverPicture, getMe, getProfilePicture, getUserDeleteToken} from "../services/userService";
import {PasswordResetRequest} from "../types/PasswordResetRequest";
import {signIn, signInUser} from "../services/signInService";
import {UserSignInRequest} from "../types/UserSignInRequest";
import {UserLoginResponse} from "../types/UserLoginResponse";
import {AxiosError} from "axios";
import {Log} from "../utils/utils";

const AuthContext = createContext(null);

const initialState = {
    me: null,
    image: null,
    cover: null,
    isLoading: false,
    token: null,
    isAuthenticated: isAuthenticated(),
    error: null,
}

function reducer(state, action) {

    switch (action.type) {
        case 'loading':
            return {
                ...state,
                isLoading: true,
                error: null,
            }

        case 'loggedin':
        case 'sign-in-completed': {
            Log.debug(action.payload);
            const res = {
                ...state,
                isLoading: false,
                me: (action.payload as UserLoginResponse)?.user,
                token: {
                    ...(action.payload as UserLoginResponse)?.bearer
                },
                isAuthenticated: true,
                error: null,
            }

            return res;
        }

        case 'users/got-owner':
            return {
                ...state,
                me: action.payload,
                isLoading: false,
                error: null,
            }
            break;

        case 'token-sent':
        case 'password-reset':

            return {
                ...state,
                isLoading: false,
                error: null
            }

        case 'loggedout':
            return {
                ...state,
                me: null,
                isLoading: false,
                isAuthenticated: false,
                error: null,
            }

        case 'got-image':
            return {
                ...state,
                image: action.payload,
                isLoading: false,
                error: null,
            }

        case 'got-cover':
            return {
                ...state,
                cover: action.payload,
                isLoading: false,
                error: null,
            }

        case 'rejected':
            Log.debug(action.payload);
            return {
                ...state,
                me: null,
                isLoading: false,
                isAuthenticated: false,
                error: action.payload,
            }

        case 'rejected-nologout':
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        default:
            throw new Error('Unknown action type')
    }


}

function AuthProvider({children}: { children: React.ReactNode }) {

    const [{me, token, image, cover, isLoading, isAuthenticated, error}, dispatch] = useReducer(reducer, initialState);

    async function login(email, password) {

        dispatch({type: 'loading'});

        try {
            const data = await userLogin(email, password);
            Log.debug(data);
            dispatch({type: 'loggedin', payload: data});
            const img = await fetchOwnerProfilePicture();
            dispatch({type: 'got-image', payload: img});
        } catch (error) {
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function refreshMe() {

        dispatch({type:'loading'});

        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getMe();
            Log.debug(data);
            dispatch({type:'users/got-owner',payload:data});
        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }
    }

    async function refreshMeForced() {

        dispatch({type:'loading'});

        try{
            const res = await refreshTokenForced();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getMe();
            console.log(data);
            dispatch({type:'users/got-owner',payload:data});
        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }
    }

    async function getSignInToken(email: string) {

        dispatch({type: 'loading'});

        try {
            await signIn({email: email});
            dispatch({type: 'token-sent'});
            return true;
        } catch (error) {
            dispatch({type: 'rejected', payload: error});
            return false;
        }

    }

    async function completeSingIn(token: string, request: UserSignInRequest) {
        dispatch({type: 'loading'});

        try {
            const data = await signInUser(token, request);
            dispatch({type: 'sign-in-completed', payload:data});
            return true;
        } catch (error) {
            dispatch({type: 'rejected', payload: error});
            return false;
        }
    }

    async function getDeleteToken() {

        dispatch({type: 'loading'});

        try {
            await getUserDeleteToken();
            dispatch({type: 'token-sent'});
        } catch (error) {
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function getResetToken(email: string) {

        dispatch({type: 'loading'});

        try {
            await getPasswordResetToken(email);
            dispatch({type: 'token-sent'});
        } catch (error) {
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function resetPassword(email: string, request: PasswordResetRequest) {

        dispatch({type: 'loading'});

        try {
            await passwordReset(email, request);
            dispatch({type: 'password-reset'});
        } catch (error) {
            dispatch({type: 'rejected', payload: error});
        }


    }

    async function removeOwner(token: string) {

        dispatch({type: 'loading'});

        try {
            await applyDeleteToken(token);
            await logout();
        } catch (error) {
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function fetchOwnerProfilePicture(){
        Log.debug('fetching owner profile picture');
        dispatch({type:'loading'});

        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getProfilePicture();
            //Log.debug(data);
            dispatch({type:'got-image',payload:data});
        }
        catch(error){
            dispatch({type:'rejected-nologout',payload:error});
        }
    }

    async function fetchOwnerCoverPicture(){
        Log.debug('fetching owner cover picture');
        dispatch({type:'loading'});

        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getCoverPicture();
            //Log.debug(data);
            dispatch({type:'got-cover',payload:data});
        }
        catch(error){
            dispatch({type:'rejected-nologout',payload:error});
        }
    }

    async function logout() {
        await userLogout();
        dispatch({type: 'loggedout'});
    }

    return <AuthContext.Provider value={{
        me,
        token,
        isLoading,
        isAuthenticated,
        error,
        image,
        cover,
        login,
        logout,
        getResetToken,
        getDeleteToken,
        resetPassword,
        removeOwner,
        getSignInToken,
        completeSingIn,
        fetchOwnerProfilePicture,
        fetchOwnerCoverPicture,
        refreshMe,
        refreshMeForced
    }}>{children}</AuthContext.Provider>;
}

function useAuth() {
    const context = useContext(AuthContext);
    if (context == undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
}

export {AuthProvider, useAuth};
