import Container from "@mui/material/Container";
import {useAuth} from "../../contexts/AuthContext";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {useEffect, useReducer, useState} from "react";
import TextField from "@mui/material/TextField";
import {Validation, ValidationType, Validator} from "../../utils/validate";
import Button from "@mui/material/Button";
import SuspenseLoader from "../SuspenseLoader";
import {useDb} from "../../contexts/DbContext";
import {AxiosError} from "axios";
import {T} from "../../utils/utils";

interface Field {
    value?: string | boolean;
    error?: string;
}

interface FieldValidation {
    email: Validator[];
    password: Validator[];
    token: Validator[];
}

const t =  async (message) =>{
    return await T(message);
}

const getSignInErrorMessage = async (error: AxiosError) => {
    let errorMessage: string | null = null;
    const errorNum = error.response?.status;
    const simpleData = error.response?.data as {
        status: number;
        detail: string;
    }
    const detailErr = simpleData.detail;

    if (detailErr) {
        if (detailErr.includes('Invalid Token'))
            return t('The inserted token is not correct');
        if (detailErr.includes('already registered'))
            return t('User is already registered')
        return detailErr;
    }

    switch (errorNum) {
        case 401:
            errorMessage = await t('User unrecognized or wrong password');
            break;
        default:
            errorMessage = await t('An issue occurred on backend ') + '('+ errorNum + ')';
            break;
    }

    return errorMessage;
}

const initializeFormValidation = async (): Promise<FieldValidation> => {

    const emailEmptyMessage = await t('Email field is required');
    const emailFormatMessage = await t('Email format is wrong');
    const requiredMessage = await t('This is a required field');

    return {
        email: [
            {
                validatorType: ValidationType.REQUIRED,
                message: emailEmptyMessage
            },
            {
                validatorType: ValidationType.EMAIL,
                message: emailFormatMessage
            }
        ],
        password: [
            {
                validatorType: ValidationType.REQUIRED,
                message: requiredMessage
            },
        ],
        token: [
            {
                validatorType: ValidationType.REQUIRED,
                message: requiredMessage
            },
        ]
    }

}

const initialState = {
    processStep: 1,
    email: {
        value: '',
        error: '',
    },
    errorToken: null,
    errorPassword: null,
    errorRepeat: null,
    request: null,
    formValidation: null,
    processError: null
}

function reducer(state, action) {

    switch (action.type) {

        case 'initialize-form-validation':
            return {
                ...state,
                formValidation: action.payload,
            };

        case 'reset':
            return initialState;

        case 'update-email':
            return {
                ...state,
                email: {
                    ...state.email,
                    value: action.payload,
                    error: null
                },

            }

        case 'update-token':
            return {
                ...state,
                request: {
                    ...state.request,
                    token: action.payload,
                },
                errorToken: null,
            }

        case 'update-password':
            return {
                ...state,
                request: {
                    ...state.request,
                    password: action.payload,
                },
                errorPassword: null,
            }

        case 'update-repeat':
            return {
                ...state,
                request: {
                    ...state.request,
                    repeat: action.payload,
                },
                errorRepeat: null,
            }

        case 'got-email': {
            const errors = action.payload.errors_email;

            return {
                ...state,
                email: {
                    ...state.email,
                    error: errors.length > 0 ? errors[0].message : ''
                },
                processStep: (errors.length > 0 ? state.processStep : 2),
            }
        }

        case 'got-request':
            {
                const errors_token = action.payload.errors_token;
                const errors_password = action.payload.errors_password;
                const errors_repeat = action.payload.errors_repeat;
                const error_mismatch =  action.payload.error_mismatch;
                const isError = errors_token.length > 0 || errors_password.length > 0 || errors_repeat.length > 0 || error_mismatch;

                return {
                    ...state,
                    processStep: (isError ? state.processStep : 3),
                    request: {
                        ...state.request,
                    },
                    errorToken: errors_token.length > 0 ? errors_token[0].message : null,
                    errorPassword: errors_password.length > 0 ? errors_password[0].message : null,
                    errorRepeat: errors_repeat.length > 0 ? errors_repeat[0].message : null,
                    processError: error_mismatch
                        ? t('Passwords do not match')
                        : null
                }

            }

        case 'got-error':
            return {
                ...state,
                processError: getSignInErrorMessage(action.payload),
            }

        default:
            throw new Error('Unknown action type')

    }

}

export default function RecoverLogin({onClose = null}) {

    const {
        error,
        isLoading,
        getResetToken,
        resetPassword
    } = useAuth();

    const [{
        email,
        processStep,
        request,
        processError,
        errorToken,
        errorPassword,
        errorRepeat,
        formValidation,
    }, dispatch] = useReducer(reducer, initialState);

    // Funzione asincrona che inizializza `formValidation` e chiama il `reducer`
    const initializeAndDispatch = async (dispatch: any) => {
        const formValidation = await initializeFormValidation();

        dispatch({
            type: 'initialize-form-validation',
            payload: formValidation,
        });
    };

    useEffect(() => {
        initializeAndDispatch(dispatch);
    }, [dispatch]);

    useEffect(() => {

        if (error){
            dispatch({type:'got-error',
                payload:error?.response?.status});
        }

    },[error]);

    useEffect(() => {

        if (processStep===3){
            dispatch({type:'reset'});
            if (onClose){
                onClose();
            }
        }

    }, [processStep]);

    async function handleFetchToken(){
        const errors = Validation(email?.value, formValidation.email).errors;

        if (errors.length === 0) {
            await getResetToken(email?.value);
        }

        dispatch({type: 'got-email',payload:{
                errors_email: errors,
            }});

    }

    async function handleResetAccount(){
        const errors_token = Validation(request?.token, formValidation.token).errors;
        const errors_password = Validation(request?.password, formValidation.password).errors;
        const errors_repeat = Validation(request?.repeat, formValidation.password).errors;
        const error_mismatch = (request?.password && request?.repeat && (request.password !== request.repeat));
        const isError = errors_token.length > 0 || errors_password.length > 0 || errors_repeat.length > 0 || error_mismatch;

        if (!isError){
            await resetPassword(email.value,request);
        }
        dispatch({type: 'got-request',payload:{
                errors_token: errors_token,
                errors_password: errors_password,
                errors_repeat: errors_repeat,
                error_mismatch: error_mismatch
            }});
    }

    const {t} = useDb();

    return (

        <Container component="main" maxWidth="xs">
            {isLoading && (<SuspenseLoader/>)}
            <CssBaseline/>

            <Box
                sx={{
                    marginTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                {processError && (<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography sx={{
                        mt: 2,
                        color: '#bf4611',
                        fontFamily: 'Oswald, sans-serif',
                        fontSize: '16px'
                    }}>{processError}</Typography>
                </Box>)}


                {(processStep === 1) && (<Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '120%',
                    textAlign: 'center'
                }}>
                    <Typography variant='h4' sx={{mt: 2, fontFamily: 'Oswald, sans-serif'}}>
                        {t('Insert your registration email')}
                    </Typography>
                    <Typography variant='h5' sx={{mt: 1, fontFamily: 'Oswald, sans-serif'}}>
                        {t('If you are registrated you will soon receive a token to reset your password')}
                    </Typography>
                </Box>)}

                <Box component="div" sx={{mt: 1, p: 0, fontFamily: 'Oswald, sans-serif', width: '100%'}}>

                    {(processStep === 1) && (
                        <TextField
                            InputProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            InputLabelProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            FormHelperTextProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("email")}
                            name="email"
                            type="text"
                            variant="filled"
                            value={email.value}
                            onBlur={(element) => dispatch({type: 'update-email', payload: element.target.value})}
                            onChange={(element) => dispatch({type: 'update-email', payload: element.target.value})}
                            error={!!email?.error}
                            helperText={email?.error}
                        />
                    )}

                    {(processStep === 2) && (

                        <>
                            <TextField
                                InputProps={{
                                    style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                                }}
                                InputLabelProps={{
                                    style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                                }}
                                FormHelperTextProps={{
                                    style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                                }}
                                margin="normal"
                                required
                                fullWidth
                                id="token"
                                label={t("token")}
                                name="token"
                                type="text"
                                variant="filled"
                                value={request?.token}
                                onBlur={(element) => dispatch({type: 'update-token', payload: element.target.value})}
                                onChange={(element) => dispatch({type: 'update-token', payload: element.target.value})}
                                error={!!errorToken}
                                helperText={errorToken}/>

                            <TextField
                                InputProps={{
                                    style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                                }}
                                InputLabelProps={{
                                    style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                                }}
                                FormHelperTextProps={{
                                    style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                                }}
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                label={t("Password")}
                                name="password"
                                type="password"
                                variant="filled"
                                value={request?.password}
                                onBlur={(element) => dispatch({type: 'update-password', payload: element.target.value})}
                                onChange={(element) => dispatch({type: 'update-password', payload: element.target.value})}
                                error={!!errorPassword}
                                helperText={errorPassword}/>

                            <TextField
                                InputProps={{
                                    style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                                }}
                                InputLabelProps={{
                                    style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                                }}
                                FormHelperTextProps={{
                                    style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                                }}
                                margin="normal"
                                required
                                fullWidth
                                id="repeat"
                                label={t("Repeat Password")}
                                name="repeat"
                                type="password"
                                variant="filled"
                                value={request?.repeat}
                                onBlur={(element) => dispatch({type: 'update-repeat', payload: element.target.value})}
                                onChange={(element) => dispatch({type: 'update-repeat', payload: element.target.value})}
                                error={!!errorRepeat}
                                helperText={errorRepeat}/>
                        </>

                    )}

                    <Button
                        size="large"
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="primary"
                        sx={{mt: 3, mb: 2, fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}
                        onClick={(e) => {
                            if (processStep === 1) {
                                handleFetchToken();
                            } else if (processStep === 2) {
                                handleResetAccount();
                            }
                        }}
                    >
                        {processStep == 1 && t('Send me the code')} {processStep == 2 && t('Reset Password')}
                    </Button>

                </Box>


            </Box>
        </Container>
    );
}

