import {Validation, ValidationType, Validator} from "../../utils/validate";
import {useDb} from "../../contexts/DbContext";
import {useAuth} from "../../contexts/AuthContext";
import * as React from "react";
import {useEffect, useReducer, useState} from "react";
import Box from "@mui/material/Box";
import {MenuItem, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {ProfileType} from "../../types/ProfileType";
import {Countries} from "../Countries";
import {User} from "../../types/User";
import "react-multi-carousel/lib/styles.css";
import {UserSignInRequest} from "../../types/UserSignInRequest";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";
import Login from "../Login";
import RecoverLogin from "../RecoverLogin";
import {getBrowserLanguage, Log, T} from "../../utils/utils";
import {signIn, signTokenIsValid} from "../../services/signInService";

interface Field {
    value?: string | Array<string> | ProfileType | boolean;
    error?: string;
}

interface FormFields {

    email: Field;
    password: Field;
    token: Field;
    firstname: Field;
    lastname: Field;
    mobile: Field;
    profileName: Field;
    profileType: Field;
    countries: Field;

}

interface FieldValidation {
    email: Validator[];
    password: Validator[];
    token: Validator[];
    firstname: Validator[];
    lastname: Validator[];
    mobile: Validator[];
    profileName: Validator[];
    profileType: Validator[];
    countries: Validator[];
}

const t =  async (message) =>{
    return await T(message);
}

const updateFormField = (obj: FormFields, key: string, validators: Validator[], value: string | Array<string> | boolean, validate = false) => {
    const errors: Validator[] = Validation(value, validators).errors;

    // const ll = {
    //     ...obj,
    //     [key]: {
    //         value,
    //         error: validate
    //             ? (errors.length > 0 ? errors[0].message : '')
    //             : '',
    //     }
    // };
    //
    // Log.debug(ll);

    return ({
        ...obj,
        [key]: {
            value,
            error: validate
                ? (errors.length > 0 ? errors[0].message : '')
                : '',
        }
    })
}

const initializeFormValidation = async (): Promise<FieldValidation> => {

    const emailEmptyMessage = await t('Email field is required');
    const emailFormatMessage = await t('Email format is wrong');
    const requiredMessage = await t('This is a required field');
    const mobileFormatMessage = await  t('Mobile number format is wrong');
    const countrySizeMessage = await t('You must choose at least a geographical area');

    return {
        email: [
            {
                validatorType: ValidationType.REQUIRED,
                message: emailEmptyMessage
            },
            {
                validatorType: ValidationType.EMAIL,
                message: emailFormatMessage
            }
        ],
        password: [
            {
                validatorType: ValidationType.REQUIRED,
                message: requiredMessage
            },
        ],
        token: [
            {
                validatorType: ValidationType.REQUIRED,
                message: requiredMessage
            },
        ],
        firstname: [
            {
                validatorType: ValidationType.REQUIRED,
                message: requiredMessage
            },
        ],
        lastname: [
            {
                validatorType: ValidationType.REQUIRED,
                message: requiredMessage
            },
        ],
        mobile: [
            {
                validatorType: ValidationType.REQUIRED,
                message: requiredMessage
            },
            {
                validatorType: ValidationType.PHONE_NUMBER,
                message: mobileFormatMessage
            },
        ],
        profileName: [
            {
                validatorType: ValidationType.REQUIRED,
                message: requiredMessage
            },
        ],
        profileType: [
            {
                validatorType: ValidationType.REQUIRED,
                message: requiredMessage
            },
        ],
        countries: [
            {
                validatorType: ValidationType.REQUIRED,
                message: countrySizeMessage
            },
            {
                validatorType: ValidationType.GREATER,
                message: countrySizeMessage,
                props: {
                    greater: 0,
                }
            },
        ]
    }

}

const clearForm = () => {

    const res: FormFields = {
        email: {value: '', error: ''},
        password: {value: '', error: ''},
        token: {value: '', error: ''},
        firstname: {value: '', error: ''},
        lastname: {value: '', error: ''},
        mobile: {value: '', error: ''},
        profileName: {value: '', error: ''},
        profileType: {value: '', error: ''},
        countries: {value: [], error: ''}
    }
    return res;
}

const getValidator = (formValidation: FieldValidation,id: string) => {

    switch (id) {
        case 'email':
            return formValidation.email;
        case 'password':
        case 'repeat':
            return formValidation.password;
        case 'token':
            return formValidation.token;
        case 'firstname':
            return formValidation.firstname;
        case 'lastname':
            return formValidation.lastname;
        case 'mobile':
            return formValidation.mobile;
        case 'profileName':
            return formValidation.profileName;
        case 'profileType':
            return formValidation.profileType;
        case 'countries':
            return formValidation.countries;
        default:
            throw new Error('Unknown id')
    }
}

const initialState = {
    processStep: 1,
    request: () => clearForm(),
    formValidation: null,
    processError: null,

}

const getSignInErrorMessage = async (error: AxiosError) => {
    let errorMessage: string | null = null;
    const errorNum = error.response?.status;
    const simpleData = error.response?.data as {
        status: number;
        detail: string;
    }
    const detailErr = simpleData.detail;

    if (detailErr) {
        if (detailErr.includes('Invalid Token'))
            return t('The inserted token is not correct');
        if (detailErr.includes('already registered'))
            return t('User is already registered')
        return detailErr;
    }

    switch (errorNum) {
        case 401:
            errorMessage = await t('User unrecognized or wrong password');
            break;
        default:
            errorMessage = await t('An issue occurred on backend ') + '('+ errorNum + ')';
            break;
    }

    return errorMessage;
}

function reducer(state, action) {

    switch (action.type) {

        case 'initialize-form-validation':
            return {
                ...state,
                formValidation: action.payload,
            };

        case 'switch-to-register':
            return {
                ...state,
                processStep: 1,
                processError: null,
            }

        case 'switch-to-login':
            return {
                ...state,
                processStep: 6,
                processError: null,
            }

        case 'switch-to-forgot-password':
            return {
                ...state,
                processStep: 7,
                processError: null,
            }

        case 'update-fields':
            return {
                ...state,
                processError: null,
                request: updateFormField(state.request, action.payload.id, getValidator(state.formValidation, action.payload.id), action.payload.value, false),
            }

        case 'next-step':
            return {
                ...state,
                processError: null,
                processStep: (state.processStep < 5 ? state.processStep + 1 : state.processStep),
            }

        case 'prev-step':
            return {
                ...state,
                processError: null,
                processStep: (state.processStep > 1 ? state.processStep - 1 : state.processStep),
            }

        case 'got-errors':
            return {
                ...state,
                processError: null,
                request: updateFormField(state.request, action.payload.id, getValidator(state.formValidation,action.payload.id), action.payload.value, true),
            }

        case 'got-fields-error':
            return {
                ...state,
                processError: action.payload,
            }

        case 'got-process-error':
            return {
                ...state,
                processError: getSignInErrorMessage(action.payload),
            }

        default:
            throw new Error('Unknown action type')
    }
}

interface OnBoardingProps {
    OnChangeOnboardingStep?:(step:number)=>void
}

const OnBoardingSignIn: React.FC<OnBoardingProps> = ({ OnChangeOnboardingStep=null }) => {

    const navigate = useNavigate();
    const {t} = useDb();

    const {
        profileTypes,
        countries,
        subscriptionTypes,
    } = useDb();

    const {
        error,
        isLoading,
        me,
        completeSingIn,
    } = useAuth();

    const [{
        processStep,
        request,
        processError,
        formValidation,
    }, dispatch] = useReducer(reducer, initialState);

    // Funzione asincrona che inizializza `formValidation` e chiama il `reducer`
    const initializeAndDispatch = async (dispatch: any) => {
        const formValidation = await initializeFormValidation();

        dispatch({
            type: 'initialize-form-validation',
            payload: formValidation,
        });
    };

    const blankUser: User = {
        id: 'none',
        name: '',
        email: '',
        firstname: '',
        lastname: '',
        mobile: '',
        created: null,
        updated: null,
        roles: [],
        profiles: [],
        countries: [],
        properties: null
    };

    const [user, setUser] = useState<User>(blankUser);

    useEffect(() => {
        initializeAndDispatch(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            dispatch({type: 'got-process-error', payload: error});
        }
    }, [error])

    useEffect(() => {
        if (me) {
            navigate(`/management/profile/details/${me.id}`, {replace: true});
        }
    }, [me]);

    useEffect(() => {

        if (OnChangeOnboardingStep){
            OnChangeOnboardingStep(processStep);
        }
    }, [processStep]);

    function onUpdateCountriesData(arg: { userId: string, countries: string[] }) {

        const newUser = {
            ...user,
            countries: [...arg.countries.map(
                sId => countries.find(
                    country => country.id === sId
                )
            )]
        };

        setUser(newUser);
        dispatch({type: 'update-fields', payload: {id: "countries", value: newUser.countries?.map((c) => c.id)}})
    }

    const handleNextButtonClick = async () => {

        Log.debug('handleNextButtonClick: processStep is: ' + processStep);

        switch (processStep) {

            case 1: {
                const errors = Validation(request?.email?.value, formValidation.email).errors;
                if (errors.length === 0) {
                    try {
                        await signIn({email: request?.email?.value});
                        dispatch({type: 'next-step'});
                    } catch (error) {
                        dispatch({type: 'got-process-error', payload: error});
                    }
                } else {
                    dispatch({type: 'got-errors', payload: {id: "email", value: request?.email?.value}});
                }
            }
                break;
            case 2: {
                const err_firstname = Validation(request?.firstname?.value, formValidation.firstname).errors;
                const err_lastname = Validation(request?.lastname?.value, formValidation.lastname).errors;
                const err_mobile = Validation(request?.mobile?.value, formValidation.mobile).errors;
                const err_token = Validation(request?.token?.value, formValidation.token).errors;
                const token_valid = await signTokenIsValid(request?.token?.value);

                let isSuccessful = true;
                if (err_firstname.length > 0) {
                    dispatch({type: 'got-errors', payload: {id: "firstname", value: request?.firstname?.value}});
                    isSuccessful = false;
                }
                if (err_lastname.length > 0) {
                    dispatch({type: 'got-errors', payload: {id: "lastname", value: request?.lastname?.value}});
                    isSuccessful = false;
                }
                if (err_mobile.length > 0) {
                    dispatch({type: 'got-errors', payload: {id: "mobile", value: request?.mobile?.value}});
                    isSuccessful = false;
                }
                if (err_token.length > 0) {
                    dispatch({type: 'got-errors', payload: {id: "token", value: request?.token?.value}});
                    isSuccessful = false;
                }
                if (!token_valid.isValid) {
                    dispatch({type: 'got-fields-error', payload: 'Il codice inserito non è corretto'});
                    isSuccessful = false;
                }
                if (isSuccessful) {
                    dispatch({type: 'next-step'});
                }
            }
                break;
            case 3: {
                const err_profileName = Validation(request?.profileName?.value, formValidation.profileName).errors;
                const err_profileType = Validation(request?.profileType?.value, formValidation.profileType).errors;
                let isSuccessful = true;
                if (err_profileName.length > 0) {
                    dispatch({type: 'got-errors', payload: {id: "profileName", value: request?.profileName?.value}});
                    isSuccessful = false;
                }
                if (err_profileType.length > 0) {
                    dispatch({type: 'got-errors', payload: {id: "profileType", value: request?.profileType?.value}});
                    isSuccessful = false;
                }
                if (isSuccessful) {
                    dispatch({type: 'next-step'});
                }
            }
                break;
            case 4: {
                const err_countries = Validation(request?.countries?.value, formValidation.countries).errors;
                if (err_countries.length > 0) {
                    dispatch({type: 'got-fields-error', payload: err_countries[0].message});
                } else {
                    //Log.debug(request.countries);
                    dispatch({type: 'next-step'});
                }
            }
                break;
            case 5: {
                const err_password = Validation(request?.password?.value, formValidation.password).errors;
                const err_repeat = Validation(request?.repeat?.value, formValidation.password).errors;
                const error_mismatch = (request?.password && request?.repeat && (request.password.value !== request.repeat.value));
                let isSuccessful = true;
                if (err_password.length > 0) {
                    dispatch({type: 'got-errors', payload: {id: "password", value: request?.password?.value}});
                    isSuccessful = false;
                }
                if (err_repeat.length > 0) {
                    dispatch({type: 'got-errors', payload: {id: "repeat", value: request?.repeat?.value}});
                    isSuccessful = false;
                }
                if (error_mismatch) {
                    dispatch({type: 'got-fields-error', payload: "Le password non corrispondono"});
                    isSuccessful = false;
                }

                if (isSuccessful) {

                    const arg: UserSignInRequest = {
                        firstname: request.firstname.value,
                        lastname: request.lastname.value,
                        mobile: request.mobile.value,
                        profileName: request.profileName.value,
                        password: request.password.value,
                        repeat: request.repeat.value,
                        profileType: request.profileType.value.id,
                        countries: request.countries.value,
                    };

                    Log.debug(arg);

                    const isSuccessful = await completeSingIn(request.token.value,
                        arg);
                }


            }
                break;
            default:
                Log.error('Unknown process Step')
                break;

        }

    }

    const handlePrevButtonClick = async () => {
        switch (processStep) {

            case 1:
                navigate('/');
                break;
            case 2:
            case 3:
            case 4:
            case 5:
                dispatch({type: 'prev-step'})
                break;
            default:
                Log.error('Unknown process Step')
                break;

        }
    }

    const handleForgotPasswordClick = () => {
        Log.debug('ForgotPassword');
        dispatch({type: 'switch-to-forgot-password'})
    }

    const handleLoginClick = () => {
        Log.debug('Login');
        dispatch({type: 'switch-to-login'})
    }

    const handleRegisterClick = () => {
        Log.debug('Register');
        dispatch({type: 'switch-to-register'})
    }


    return (

        <Box
            sx={{
                marginTop: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                maxWidth: { xs: '100%', sm: '600px' }, // max width per mobile e desktop
                marginX: 'auto', // centra orizzontalmente
                paddingX: { xs: 2, sm: 4 }, // padding adattivo
            }}
        >
            {(processError && processStep!==6) && (<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Typography sx={{
                    mt: 2,
                    color: '#bf4611',
                    fontFamily: 'Oswald, sans-serif',
                    fontSize: { xs: '14px', sm: '16px' },
                    textAlign: 'center',
                }}>{processError}</Typography>
            </Box>)}

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center'
            }}>

                {(processStep === 1) && (
                    <>
                        <Typography variant='h4' sx={{
                            mt: 2,
                            fontFamily: 'Oswald, sans-serif',
                            fontSize: { xs: '18px', sm: '24px' },
                        }}>
                            {t('Insert your email to register')}
                        </Typography>
                        <Typography variant='h5' sx={{
                            mt: 1,
                            fontFamily: 'Oswald, sans-serif',
                            fontSize: { xs: '14px', sm: '18px' },
                        }}>
                            {t('A token will be sent to your email and will be required in the next registration steps')}
                        </Typography>
                    </>
                )}

                {(processStep === 2) && (
                    <Typography variant='h4' sx={{
                        mt: 2,
                        fontFamily: 'Oswald, sans-serif',
                        fontSize: { xs: '18px', sm: '24px' },
                    }}>
                        {t('Insert your details and the received token')}
                    </Typography>
                )}

                {(processStep === 3) && (
                    <Typography variant='h4' sx={{
                        mt: 2,
                        fontFamily: 'Oswald, sans-serif',
                        fontSize: { xs: '18px', sm: '24px' },
                    }}>
                        <span>{t('Insert a new Profile')}</span>
                    </Typography>
                )}

                {(processStep === 4) && (
                    <Typography variant='h4' sx={{
                        mt: 2,
                        fontFamily: 'Oswald, sans-serif',
                        fontSize: { xs: '18px', sm: '24px' },
                    }}>
                        <span>{t('Insert your geographical availability')}</span>
                    </Typography>
                )}

                {(processStep === 5) && (
                    <Typography variant='h4' sx={{
                        mt: 2,
                        fontFamily: 'Oswald, sans-serif',
                        fontSize: { xs: '18px', sm: '24px' },
                    }}>
                        <span>{t('Create a password')}</span>
                    </Typography>
                )}

            </Box>

            <Box component="div" sx={{mt: 1, p: 0, fontFamily: 'Oswald, sans-serif', width: '100%'}}>

                {(processStep === 1) && (
                    <TextField
                        InputProps={{
                            style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                        }}
                        InputLabelProps={{
                            style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                        }}
                        FormHelperTextProps={{
                            style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                        }}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="email"
                        name="email"
                        type="text"
                        variant="filled"
                        value={request?.email?.value ? request?.email?.value : ''}
                        onBlur={(element) => dispatch({
                            type: 'update-fields',
                            payload: {id: "email", value: element.target.value}
                        })}
                        onChange={(element) => dispatch({
                            type: 'update-fields',
                            payload: {id: "email", value: element.target.value}
                        })}
                        error={!!request?.email?.error}
                        helperText={request?.email?.error}
                    />
                )}

                {(processStep === 2) && (

                    <Box sx={{mt: 1, p: 0, fontFamily: 'Oswald, sans-serif', width: '100%'}}>
                        <TextField
                            InputProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            InputLabelProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            FormHelperTextProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="firstname"
                            label={t("First Name")}
                            name="firstname"
                            type="text"
                            variant="filled"
                            value={request?.firstname?.value ? request?.firstname?.value : ''}
                            onBlur={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "firstname", value: element.target.value}
                            })}
                            onChange={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "firstname", value: element.target.value}
                            })}
                            error={!!request?.firstname?.error}
                            helperText={request?.firstname?.error}/>

                        <TextField
                            InputProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            InputLabelProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            FormHelperTextProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="lastname"
                            label={t("Last Name")}
                            name="lastname"
                            type="text"
                            variant="filled"
                            value={request?.lastname?.value ? request?.lastname?.value : ''}
                            onBlur={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "lastname", value: element.target.value}
                            })}
                            onChange={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "lastname", value: element.target.value}
                            })}
                            error={!!request?.lastname?.error}
                            helperText={request?.lastname?.error}/>

                        <TextField
                            InputProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            InputLabelProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            FormHelperTextProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="mobile"
                            label={t("Mobile")}
                            name="mobile"
                            type="tel"
                            variant="filled"
                            value={request?.mobile?.value ? request?.mobile?.value : ''}
                            onBlur={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "mobile", value: element.target.value}
                            })}
                            onChange={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "mobile", value: element.target.value}
                            })}
                            error={!!request?.mobile?.error}
                            helperText={request?.mobile?.error}/>

                        <TextField
                            InputProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            InputLabelProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            FormHelperTextProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="token"
                            label={t("Token")}
                            name="token"
                            type="password"
                            variant="filled"
                            value={request?.token?.value ? request?.token?.value : ''}
                            onBlur={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "token", value: element.target.value}
                            })}
                            onChange={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "token", value: element.target.value}
                            })}
                            error={!!request?.token?.error}
                            helperText={request.token?.error}/>

                    </Box>
                )}

                {(processStep === 3) && (

                    <Box sx={{mt: 1, p: 0, fontFamily: 'Oswald, sans-serif', width: '100%'}}>

                        <TextField
                            InputProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            InputLabelProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            FormHelperTextProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="profileName"
                            label={t("Profile Name")}
                            name="profileName"
                            type="text"
                            variant="filled"
                            value={request?.profileName?.value ? request?.profileName?.value : ''}
                            onBlur={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "profileName", value: element.target.value}
                            })}
                            onChange={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "profileName", value: element.target.value}
                            })}
                            error={!!request?.profileName?.error}
                            helperText={request?.profileName?.error}/>

                        <TextField
                            InputProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px', textAlign: 'left'} // Cambia il font
                            }}
                            InputLabelProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            FormHelperTextProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="profileType"
                            select
                            label={t("Choose your profile")}
                            value={(request?.profileType?.value)
                                ? request?.profileType.value.name
                                : ''}
                            onChange={(element) => {
                                const profile = profileTypes.find((profile) => profile.name === element.target.value);
                                dispatch({type: 'update-fields', payload: {id: "profileType", value: profile}})
                            }
                            }
                            onBlur={(element) => {
                                const profile = profileTypes.find((profile) => profile.name === element.target.value);
                                dispatch({type: 'update-fields', payload: {id: "profileType", value: profile}})
                            }
                            }
                            error={!!request?.profileType?.error}
                            helperText={request?.profileType?.error}
                        >
                            {profileTypes?.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>

                    </Box>

                )}

                {(processStep === 4) && (
                    <>
                        <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Countries user={user} onUpdateCountriesData={onUpdateCountriesData} isEditing={true}
                                       language={getBrowserLanguage()} label={t('Add a country')}/>
                        </Box>
                    </>
                )}

                {(processStep === 5) && (

                    <Box component="div" sx={{mt: 1, p: 0, fontFamily: 'Oswald, sans-serif', width: '100%'}}>

                        <TextField
                            InputProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            InputLabelProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            FormHelperTextProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label={t("Password")}
                            name="password"
                            type="password"
                            variant="filled"
                            value={request?.password?.value ? request?.password?.value : ''}
                            onBlur={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "password", value: element.target.value}
                            })}
                            onChange={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "password", value: element.target.value}
                            })}
                            error={!!request?.password?.error}
                            helperText={request?.password?.error}/>

                        <TextField
                            InputProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            InputLabelProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            FormHelperTextProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="repeat"
                            label={t("Repeat Password")}
                            name="repeat"
                            type="password"
                            variant="filled"
                            value={request?.repeat?.value ? request?.repeat?.value : ''}
                            onBlur={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "repeat", value: element.target.value}
                            })}
                            onChange={(element) => dispatch({
                                type: 'update-fields',
                                payload: {id: "repeat", value: element.target.value}
                            })}
                            error={!!request?.repeat?.error}
                            helperText={request?.repeat?.error}/>

                    </Box>

                )}

            </Box>

            {processStep < 6 && (
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 2, sm: 4 },
                    width: '100%',
                    mt: 2,
                }}>

                    <Button
                        size="large"
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="primary"
                        sx={{
                            fontFamily: 'Oswald, sans-serif',
                            fontSize: '16px',
                        }}
                        onClick={(e) => handlePrevButtonClick()}
                    >
                        {processStep == 1 && t('Back to Home')} {processStep > 1 && t('Back')}
                    </Button>


                    <Button
                        size="large"
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="primary"
                        sx={{
                            fontFamily: 'Oswald, sans-serif',
                            fontSize: '16px',
                        }}
                        onClick={(e) => handleNextButtonClick()}
                    >
                        {processStep == 1 && t('Register')} {(processStep > 1 && processStep < 5) && t('Next')} {processStep == 5 && t('Register!')}
                    </Button>

                </Box>
            )}

            {processStep === 6 && (
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'column' },
                    gap: { xs: 2, sm: 4 },
                    width: '100%',
                    mt: 2,
                }}>
                    <Typography variant='h4' sx={{
                        mt: 2,
                        fontFamily: 'Oswald, sans-serif',
                        fontSize: { xs: '18px', sm: '24px' },
                    }}>
                        <span>{t('Login')}</span>
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%'}}>

                        <Login/>

                        <Box sx={{
                            flexGrow: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 10
                        }}>

                            <Button
                                size="large"
                                type="submit"
                                fullWidth
                                color="secondary"
                                sx={{mt: 1, mb: 2, mr: 2, fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}
                                onClick={(e) => handleForgotPasswordClick()}
                            >
                                {t('Forgot Password')}
                            </Button>

                            <Button
                                size="large"
                                type="submit"
                                fullWidth
                                color="secondary"
                                sx={{mt: 1, mb: 2, mr: 2, fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}
                                onClick={(e) => handleRegisterClick()}
                            >
                                {t('Register')}
                            </Button>

                        </Box>
                    </Box>
                </Box>
            )}

            {processStep === 7 && (
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'column' },
                    gap: { xs: 2, sm: 4 },
                    width: '100%',
                    mt: 2,
                }}>
                    <Typography variant='h4' sx={{
                        mt: 2,
                        fontFamily: 'Oswald, sans-serif',
                        fontSize: { xs: '18px', sm: '24px' },
                    }}>
                        <span>{t('Reset Password')}</span>
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%'}}>
                        <RecoverLogin onClose={() => {
                            Log.debug('Recover login');
                        }}/>
                        <Box sx={{
                            flexGrow: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 10
                        }}>

                            <Button
                                size="large"
                                type="submit"
                                fullWidth
                                color="secondary"
                                sx={{mt: 1, mb: 2, mr: 2, fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}
                                onClick={(e) => handleRegisterClick()}
                            >
                                {t('Sign up')}
                            </Button>

                            <Button
                                size="large"
                                type="submit"
                                fullWidth
                                color="secondary"
                                sx={{mt: 1, mb: 2, mr: 2, fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}
                                onClick={(e) => handleLoginClick()}
                            >
                                {t('Login')}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}

            {processStep === 1 && (<Typography component="div" sx={{ width: '100%', mt: 2 }}>

                    <Button
                        size="large"
                        type="submit"
                        fullWidth
                        color="secondary"
                        sx={{
                            fontFamily: 'Oswald, sans-serif',
                            fontSize: '16px',
                        }}
                        onClick={(e) => handleLoginClick()}
                    >
                        {t('Login')}

                    </Button>

                </Typography>

            )}

        </Box>
    );
}

export default OnBoardingSignIn;
