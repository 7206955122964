import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import Box from "@mui/material/Box";
import {Button, Typography} from "@mui/material";
import SubscriptionSelect from "../../components/SubscriptionSelect";
import {User} from "../../types/User";
import {useAuth} from "../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import {Log} from "../../utils/utils";
import Grid from "@mui/material/Grid2";
import PageFooter from "../../components/PageFooter";
import {useDb} from "../../contexts/DbContext";

const SubscriptionPage: React.FC = () => {

    const {me, refreshMe, isAuthenticated} = useAuth();
    const {t} = useDb();
    const [selected, setSelected] = React.useState<string>(null);

    useEffect(() => {
        if (!me)
            refreshMe();
    }, [me]);

    const navigate = useNavigate();

    const handleSkipButtonClick = ()=>{
        Log.debug("handleSkipButtonClick");
        if (me && isAuthenticated){
            navigate(`/management/profile/details/${me.id}`,{replace:true});
        }
        else{
            navigate('/');
        }
    }

    const handleSubscribeButtonClick = (id: string)=>{
        Log.debug('Selected: ' + id);
        //TODO: here we must place the payment process. For now we go the detail profile
        if (me && isAuthenticated){
            navigate(`/management/profile/details/${me.id}`,{replace:true});
        }
        else{
            navigate('/');
        }
    }

    return (
        <Box sx={{
            background: 'url(/static/images/home/subscription-background.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            display: 'flex',
            flexDirection: 'column',
            gap:2,
            height: "100%", width: '100%'
        }}>
            <Helmet>
                <title>The Specialist - {t('Subscriptions')}</title>
            </Helmet>

            {/*Sezione Funzionalità*/}
            <Box sx={{
                display: 'flex',
                width: '100%',
                flexGrow: '0',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}>
                <Grid container spacing={2} justifyContent='center' sx={{mt:3, mb:4}}>
                    <Grid size={{xs:12,md:4}}>
                        <Box textAlign="center">
                            <Typography variant="h3" sx={{fontFamily: 'Oswald, sans-serif'}}>{t('Select your Subscription')}</Typography>
                            <Typography variant="body2" sx={{fontFamily: 'Oswald, sans-serif'}}>{t('Choose your favourite formula')}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            <SubscriptionSelect onChange={(s) => {
                setSelected(s);
            }}/>

            <Box sx={{display: 'flex', flexDirection: 'row', alignItems:'center' ,justifyContent: 'center', width: '100%', mt:4}}>

                {selected && (
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        sx={{mt: 0, mb: 2, mr: 2, fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}
                        onClick={(e) => handleSubscribeButtonClick(selected)}
                    >
                        {t('Select')}
                    </Button>
                )}

                <Button
                    size="large"
                    variant="contained"
                    type="submit"
                    color="primary"
                    sx={{mt: 0, mb: 2, mr: 0, ml:2, fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}
                    onClick={(e) => handleSkipButtonClick()}
                >
                    {t('Skip for now')}
                </Button>

            </Box>

            <PageFooter/>

        </Box>
    );
}

export default SubscriptionPage;
