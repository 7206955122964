import {styled} from "@mui/material/styles";
import {Typography} from "@mui/material";
import {Log} from "../../utils/utils";

const SkillItemWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
}));

const SkillName = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontFamily: 'Oswald, sans-serif',
    fontSize: '16px'
});

const DeleteButton = styled('button')(({ theme }) => ({
    marginLeft: theme.spacing(1),
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1.2rem',
    color: theme.palette.error.main,
}));
export interface SkillType {
    id: string,
    name : string
}

interface SkillProps{
    skill: SkillType,
    isEditing: boolean,
    language: string,
    onDelete: (e:any) => void;
}

export const SkillItem: React.FC<SkillProps>=({skill,isEditing,language, onDelete})=> {

    function handleClick(e){
        e.preventDefault();
        Log.debug('CountryItem handleClick');
        onDelete(e);
    }

    return (
         <SkillItemWrapper>
             <SkillName>{skill.name}</SkillName>
             {isEditing && (
                 <DeleteButton onClick={handleClick}>&times;</DeleteButton>
             )}
         </SkillItemWrapper>
    );

}
