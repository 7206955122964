import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

interface ModalDialogProps {
    id?: string;
    label?: string;
    title?: string;
    buttonLabel?: string;
    open: boolean; // Stato che controlla l'apertura
    onClose: () => void;// Funzione di chiusura
}

const ModalDialog: React.FC<ModalDialogProps> = ({ id='a',label, title, buttonLabel='OK', open, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="modal-dialog-title"
            aria-describedby="modal-dialog-description"
        >
            <DialogTitle id={`${id}-modal-dialog-title`} sx={{fontFamily: 'Oswald, sans-serif'}}>{title}</DialogTitle>
            <DialogContent>
                <Typography id={`${id}-modal-dialog-description`} sx={{fontFamily: 'Oswald, sans-serif'}}>
                    {label}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" autoFocus sx={{fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}>
                    {buttonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalDialog;
