import {SignIn} from "../types/SignIn";
import {UserSignInRequest} from "../types/UserSignInRequest";
import {api} from "../utils/api";
import {SignInVerify} from "../types/SignInVerify";

export const signIn = async (request: SignIn): Promise<void> => {
    await api.post(`/api/sign-in`, request);
};

export const signInUser = async (token: string, request: UserSignInRequest): Promise<void> => {
    await api.patch(`/api/sign-in/${token}`, request);
};

export const signTokenIsValid = async (token: string): Promise<SignInVerify> => {
    return await api.get(`/api/sign-in/${token}/verify`);
};